import './FooterSection.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Matter from "matter-js";
import { useEffect, useRef } from 'react';
import instagram from '../../img/icon-insta.png';
import facebook from '../../img/icon-fb.png';
import twitter from '../../img/icon-twitter.png';
import Linkdin from '../../img/icon-linkdin.png';
import gsap from 'gsap';
import arrowleft from '../../img/home-assets/arrow-right.svg';

const FooterSection = () => {

    const footerScene= useRef();

    useEffect(()=>{
        
        var Engine = Matter.Engine,
            Render = Matter.Render,
            World = Matter.World,
            Bodies = Matter.Bodies;
        var engine = Engine.create();
        var render = Render.create({
            element: footerScene.current,
            engine: engine,
            options: {width: 1100,height:800,background:'transparent',wireframes: false,friction:0,
            frictionAir:0,}
        });

        const footeranim = ()=> {
            var Instagram = Bodies.rectangle(102, 100, 110, 110,{chamfer: { radius: [50,50,0,0], quality:10}, render: {sprite: {texture: instagram}},url:'https://www.instagram.com'});
            var Facebook = Bodies.rectangle(120, 100, 110, 110,{chamfer: { radius: [50,0,0,0],quality:10},render: {sprite: {texture: facebook}},url:'https://www.facebook.com'});
            var Twitter = Bodies.rectangle(109, 100, 110, 110,{chamfer: { radius: [0,0,50,0],quality:10},render: {sprite: {texture: twitter}},url:'https://www.facebook.com'});
            var Linkedin = Bodies.rectangle(112, 100, 110, 110,{chamfer: { radius: [50,0,50,0],quality:10},render: {sprite: {texture: Linkdin}},url:'https://www.facebook.com'});
            var ground = Bodies.rectangle(500, 800, 1200, 10, { isStatic: true });
            var wallLeft = Bodies.rectangle( -80, window.innerHeight / 2, 160, window.innerHeight, { isStatic: true });
            var wallRight = Bodies.rectangle(window.innerWidth + 80, window.innerHeight / 2, 160, 1200, { isStatic: true });
            ground.render.visible = false;
            wallLeft.render.visible = false;
            wallRight.render.visible = false;
            World.add(engine.world, [Facebook,Twitter,Instagram,Linkedin, ground,wallLeft,wallRight]);
            Matter.Runner.run(engine);
            Render.run(render);
            return () => {
                Render.stop(render)
                World.clear(engine.world)
                Matter.Runner.stop(engine);
                Engine.clear(engine)
                render.canvas.remove()
                render.canvas = null
                render.context = null
                render.textures = {}
            }
        }
        const text=document.querySelector('.text p');
        text.innerHTML = text.innerText.split("").map(
            (char, i )=>
            `<span  style="transform:rotate(${i*15}deg)">${char}</span>`
        ).join("")
        const observer = new IntersectionObserver((entries)=>{
            const entry = entries[0];
            if(entry.isIntersecting===true){
                footeranim();

                

            }else{
                World.clear(engine.world)
            }
        });
        observer.observe(footerScene.current);
    },[]);


    return ( 
        <footer className='bgdark' ref={footerScene} id="contactus">
            <div className='container'>
                <div className="row">
                    <div className="col-12">
                        <h2>
                            Let's Create Something<br/><span>Great Together </span>
                        </h2>
                    </div>    
                </div>
                <div className="row">
                    <div className="col-sm-4">

                        <div className="contact-info">
                            <h3>Contact Us </h3>
                            <p>
                                1916 Pike Place Suite #12 <br/>
                                1422 Seattle, WA 98101 U.S.A.
                            </p>
                            <p><span>Office:</span> 206-696-2778</p>
                            <p><span>Marketing:</span> 360-797-5576</p>
                            <p>Contact@artesi8.com</p>
                        </div>
                        
                    </div>
                    <div className='col-sm-6'>
                        <div className="contact-form">
                            <form>
                                <label>
                                    <input type="text" name="name" placeholder="Your name*" />
                                </label> 
                                <label>
                                    <input type="email" name="email" placeholder="Your email address**" />
                                </label> 
                                <label>
                                    <input type="text" name="subject" placeholder="Describe your needs*" />
                                </label> 
                                <div className="circle object" data-value='-5' >
                                    <input type="submit" name="submit" value='submit now'/>
                                    <img src={arrowleft} alt=""/>
                                    <div className="text ">
                                        <p>Feel Free to Contact us</p>
                                    </div>
                                </div>
                                
                            </form>
                            
                        </div>
                    </div>

                </div>
            </div>
            <div className='copyright float-right'>
                <img src={require('.././../img/home-assets/6.png')} alt="rectangle" /><p>Copyright © Artesi8</p>
            </div>
        </footer>
     );
}
 
export default FooterSection;