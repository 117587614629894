const partnerDetails = [
    { 
      id:1,
      name: "CementMixer",
      image: `${require(".././../img/logo/logo1.png")}`,
      quote:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis aenean mi auctor a sed cursus enim. Vitae, semper massa ullamcorper aliquam, etiam. Consectetur urna quis est, sollicitudin eget dictum. Sagittis in vestibulum gravida sed malesuada aliquam libero id risus"
    },
    {
      id:2,
      name: "Dust Mask",
      image: `${require(".././../img/logo/logo2.png")}`,
      quote:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis aenean mi auctor a sed cursus enim. Vitae, semper massa ullamcorper aliquam, etiam. Consectetur urna quis est, sollicitudin eget dictum. Sagittis in vestibulum gravida sed malesuada aliquam libero id risus"
    },
    {
      id:3,
      name: "Putty Knife",
      image:`${require(".././../img/logo/logo3.png")}`,
      quote:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis aenean mi auctor a sed cursus enim. Vitae, semper massa ullamcorper aliquam, etiam. Consectetur urna quis est, sollicitudin eget dictum. Sagittis in vestibulum gravida sed malesuada aliquam libero id risus"
    },
    {
      id:4,
      name: "Utility Knife",
      image:`${require(".././../img/logo/logo4.png")}`,
      quote:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis aenean mi auctor a sed cursus enim. Vitae, semper massa ullamcorper aliquam, etiam. Consectetur urna quis est, sollicitudin eget dictum. Sagittis in vestibulum gravida sed malesuada aliquam libero id risus"
    },
    {
      id:5,
      name: "Hot Water Bottle",
      image:`${require(".././../img/logo/logo5.png")}`,
      quote:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis aenean mi auctor a sed cursus enim. Vitae, semper massa ullamcorper aliquam, etiam. Consectetur urna quis est, sollicitudin eget dictum. Sagittis in vestibulum gravida sed malesuada aliquam libero id risus"
    }
    
];

export default partnerDetails