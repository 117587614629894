import React from "react";
import HeroSection from "./HeroSection/HeroSection";
import AboutSection from "./AboutSection/AboutSection";
import DescriptionSection from "./DescriptionSection/DescriptionSection"; 
import TeamSection from "./TeamSection/TeamSection";
import Partners from "./Partners/Partners";

const Home = () => {
    
    return (  
        <>   
            <HeroSection/>
            <AboutSection/>
            <DescriptionSection/>
            <Partners/> 
            <TeamSection/>
        </>
    );
}
 
export default Home;