import React from "react";
import './style.css';
import TeamSlide from './TeamSlider/TeamSlider';

const About = () => {
    return (  
        <>
            <div className="about-wrapper bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                            <div className="intro">
                                <h2>Get to </h2>
                                <h2><b>know us</b></h2>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                            <div className="banner-img">
                                <img src={require('../img/about-assets/banner1.png')} alt="banner1" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div className="parallax">
                                <img src={require('../img/about-assets/item2.jpg')} className="quoteparllax object" data-value="8"  alt="parallax"/>
                                <img src={require('../img/about-assets/item1.png')} className="object" data-value="5"  alt="parallax"/>
                            </div>
                            <div className="aboutintro">
                                <p>In the age of online search, however - the right words and pictures can be worth more than a thousand search results!</p>
                                <img src={require('../img/about-assets/banner2.png')} alt="banner2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="who-we-are bg-black">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9 ">
                            <div className="info">
                                <h2>Who We Are?</h2>
                                <img src={require('../img/about-assets/item4.png')}/>
                                <p>
                                    We have been having fun with this concept since 2002. We have enabled many people to specifically find what they are looking for via the shorter path by deploying: direct navigation, iconography and hierarchical categorization.The fun part of this process is that we are able to immerse ourselves in contextual surroundings of any given project. Not only does this appeal to our inner artists but we are able to pass on teachable moments that we discover along the way.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="parallax">
                                <img src={require('../img/about-assets/item3.png')} />
                            </div>
                        </div>                   
                    </div>
                </div>
            </div>
            <div className="ourTeam bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="title">
                                <h2>Our Team</h2>
                                <p>Our team is a diverse group with a passion for the arts who enjoy<br/> creating and making a difference</p>
                            </div>
                        </div>
                        <TeamSlide/>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default About;