import './AboutSection.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import aboutImg from '../../img/home-assets/about.svg'

const AboutSection = () => {
    return ( 
        <section className="about-section bgdark">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-12 ">
                        <img src={aboutImg} alt="about" data-aos="fade-down"/>
                    </div>
                    <div className="about-block row">
                        <div className="col-sm-6 col-md-6 col-lg-5">
                            <div className="aboutinfo" data-aos="fade-up">
                                <h2 >About</h2>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-7 ">
                            <div className="about-desc" data-aos="fade-up">
                                <p>It’s always been true that a picture is worth a thousand words.</p>
                                <p>In the age of online search, however - <span>the right words and pictures
                                </span> can be worth more than a thousand search results!</p>
                                <button className='btn-sty-1'>Discover More</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
     );
}
 
export default AboutSection;