
import { useEffect, useState } from 'react';
import {BrowserRouter , Route, Routes} from 'react-router-dom';
import Headersection from './Components/Headersection/Headersection';
import FooterSection from './Components/FooterSection/FooterSection';
import Home from './Components/Home';
import About from './Components/About';
import Partner from "./Components/Partner";
import AOS from "aos";
import 'aos/dist/aos.css';
import PreLoader from './Components/Preloader';

function App() {

  const [completed, setcompleted] = useState(undefined);

  useEffect(()=>{
    const onPageLoad = ()=>{
      setTimeout(() => {
        setcompleted(true);
      }, 5500);
    }
    if(document.readyState==='complete'){
      onPageLoad();
    }
    else{
      window.addEventListener("load", onPageLoad);
      return () => window.removeEventListener("load", onPageLoad);
    }
  })
  return (
    <>
      {!completed ? (
        <>
          {/* Preloader  */}
          <PreLoader/>
          {/* Preloader Ends */}
        </>
        ):(
          <>
            <BrowserRouter>
              <Headersection/>
              <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/about' element={<About/>}/>
                <Route path='/partner' element={<Partner/>}/>
              </Routes>
            
            </BrowserRouter>
            <FooterSection/>
          </>
        )
      }
    </>
  );
}

AOS.init({
  duration:1800
});

export default App;
