import './TeamSection.css';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import Slide from './Slide'

const TeamSection = () => {
 
    return (  
        <>
        <section className="team-section">
            <div className="container">
                <div className="row">
                    <div className="col-sm-5">
                        <h2>Our Team</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Habitasse eu sed faucibus magna
                           vel libero, lorem. Quis viverra in habitant.</p>
                    </div>
                    <div className="col-sm-7">
                        <div className="float-right">
                            <button className='btn-sty-3'>
                                Discover More          
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Slide/>
        </>

    );
}
 
export default TeamSection;