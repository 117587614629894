import './Partners.css';
import { useState } from 'react';
import arrowLeft from '../../img/home-assets/arrow-left.svg';
import arrowRight from '../../img/home-assets/arrow-right.svg';
import partnerDetail from './partnerDetail';
import {gsap, Power3 }from 'gsap';

const Partners = () => {

    const [sliderIndex, setSliderIndex] = useState(1);

    const animation = () =>{
        gsap.from('.logo',{
            x:-200,
            opacity:0,
            ease:Power3.ease
        })
        gsap.to('.logo',{
            x:0,
            opacity:1
        })

        gsap.from('.content-contain',{
            x:50,
            opacity:0,
            duration:0.5,
            ease:Power3.easeOut
        })
        gsap.to('.content-contain',{
            x:0,
            opacity:1,
            duration:0.8,
            ease:Power3.easeOut
        })
    }

    const nextSlide = () => { 
        if(sliderIndex !== partnerDetail.length){
            setSliderIndex(sliderIndex + 1);
        } 
        else if (sliderIndex === partnerDetail.length){
            setSliderIndex(1); 
        }
        animation();
    }
    
    const prevSlide = () => { 
        if(sliderIndex === 1){
            setSliderIndex(partnerDetail.length);
        } 
        else{
            setSliderIndex(sliderIndex - 1);
        } 
    }

    return ( 
        <div className="partner-wrapper bg-black"> 
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="top-title">
                            <h2>Our Partner Companies</h2>
                            <img src={require('../../img/home-assets/4.png')}  alt=""/>
                        </div>
                    </div>
                </div>  
            </div>
            {partnerDetail.map((obj, index) => {
                return(
                <div key={obj.id}
                    className={sliderIndex === index + 1 ? " active-anim" : "Partnerslide"}>
                    <div className="slider-wrap">
                        <div className="logo-contain">
                            <div className="bg-img">
                                <img data-aos="slide-right" src={require('../../img/home-assets/partner2.png')} alt=""/>
                                <img data-aos="slide-right" src={require('../../img/home-assets/partner1.png')} alt=""/>
                            </div>
                            <div className="logo">
                                <img data-aos="slide-right" data-aos-delay="500" src={obj.image}  alt=""/>
                            </div>
                            
                            <img data-aos="slide-right" data-aos-delay="500" src={require('../../img/home-assets/5.png')} className="arrow-curve" alt=""/>
                        </div>
                        <div className="content-contain">
                            <h3>{obj.name}</h3>
                            <p> 
                                {obj.quote}
                            </p>
                            <button className='btn-sty-2'>Customer Experience</button>
                        </div>
                    </div>
                    <div className="navigator">
                        <div className="btn-left"><button onClick={prevSlide}>Prev<img src={arrowLeft} alt=""/></button></div>
                        <div className="btn-right"><button  onClick={nextSlide}><img src={arrowRight} alt=""/>Next</button></div>
                    </div> 
                </div>  
                )
            })}
            
        </div>
     );
}
 
export default Partners;