import img1 from '../../img/home-assets/team-1.png';
import img2 from '../../img/home-assets/team-2.png';
import img3 from '../../img/home-assets/team-3.jpg';
import img4 from '../../img/home-assets/team-4.png';
import img5 from '../../img/home-assets/team-5.png';

const dataSlider = [
    {
      id: 1,
      title: "Karl Shield",
      subTitle: "Business Developer",
      img:img1
    },
    {
      id: 2,
      title: "Sam Willson",
      subTitle: "Supervisor",
      img:img2
    },
    {
      id: 3,
      title: "Ronald Jhonson",
      subTitle: "Director",
      img:img3
    },
    {
      id: 4,
      title: "Thomas Knight",
      subTitle: "CEO",
      img:img4
    },
    {
      id: 5,
      title: "Robert Spark",
      subTitle: "CFO",
      img:img5
    },
  ];
  
  export default dataSlider;