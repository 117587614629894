import './Slide.css';
import { useRef} from 'react';
import { gsap, Power3,Power4 } from 'gsap'; 
import arrowLeft from '../../img/home-assets/arrow-left.png';
import arrowRight from '../../img/home-assets/arrow-right.png';


const Slider = () => {

    let sliderData= useRef(null);
    let currentslide= 0;
    let prevslide=null;
    let nextslide=null;
    let hidenslide=null;
    const tl = gsap.timeline();
    const tl2 = gsap.timeline();
    const tl3 = gsap.timeline();
    
    const SlideShow  = () => {

        tl
        .to(sliderData.children[currentslide],{
            opacity:1, 
            scale:1.8, 
            x:0,
            y:0,
            ease:Power3.ease,
        }) 

        tl2
        .to(sliderData.children[prevslide],{
            opacity:1, 
            scale:1,
            ease:Power3.ease
        })

        tl3
        .to(sliderData.children[nextslide],{
            opacity:1, 
            scale:1,
            ease:Power4.ease
        })

    }

    const ReverseSlideShow  = () => {
        
        tl 
        .to(sliderData.children[prevslide],{ 
            scale:1,
            x: 0,
            y: 0,
            ease:Power3.ease
        })

        tl2 
        .to(sliderData.children[nextslide],{
            
            scale:1,
            opacity:1,
            x: -10,
            y: 0,
            ease:Power4.ease
        })

        tl3
        .to(sliderData.children[currentslide],{
            scale:1.8,
            x: 10,
            y: 10,
            ease:Power3.ease
        })
    
    }

    const slides = () =>{
        sliderData.children[prevslide].classList.add('prevslide');
        sliderData.children[nextslide].classList.add('nextslide');
        sliderData.children[hidenslide].classList.add('hideslide');
        if(
            sliderData.children[currentslide].classList.contains('prevslide')||
            sliderData.children[currentslide].classList.contains('nextslide')||
            sliderData.children[currentslide].classList.contains('hideslide')
        ){  
            sliderData.children[currentslide].classList.remove('prevslide','nextslide','hideslide');
            sliderData.children[currentslide].classList.add('currentslide');
        }

        // for next slide
        if( sliderData.children[nextslide].classList.contains('currentslide')||
            sliderData.children[nextslide].classList.contains('prevslide')||
            sliderData.children[nextslide].classList.contains('hideslide')
        )
        {
            sliderData.children[nextslide].classList.remove('currentslide','prevslide','hideslide');
        }

        //for previous slide
        if( sliderData.children[prevslide].classList.contains('currentslide')||
            sliderData.children[prevslide].classList.contains('nextslide')||
            sliderData.children[prevslide].classList.contains('hideslide')
        )
        {
            sliderData.children[prevslide].classList.remove('currentslide','nextslide','hideslide');
        }
        if( sliderData.children[hidenslide].classList.contains('currentslide')||
            sliderData.children[hidenslide].classList.contains('nextslide')||
            sliderData.children[hidenslide].classList.contains('prevslide')
        )
        {
            sliderData.children[hidenslide].classList.remove('currentslide','nextslide','prevslide');
        }
    }

    const playNext = () => {
    
        //Loop for the slider.
        if(currentslide === 3){
            currentslide=0;
        }
        else{ 
            currentslide=currentslide+1;
        }
 
        //To reset the oldslide Index 
        if(currentslide===0){
            prevslide=3;
        }
        else{
            prevslide= currentslide-1
        }

        //To reset the Newslide Index if 
        if(currentslide>=3){ 
            nextslide=0; 
        }
        else{
            nextslide= currentslide+1;
        }

        //hide the div 
        hideSlide();
        SlideShow();
        slides();
    }

    const playLast = () => {
        //Loop for the slider.
        if(currentslide === 0){
            currentslide=3;
        }
        else{
            currentslide=currentslide-1;
        }

        //
        if(currentslide>=3){
            nextslide=0;
        }else{
            nextslide=currentslide+1;
        }

        //
        if(currentslide===0){
            prevslide=3;
        }else{
            prevslide=currentslide-1;  
        } 
        
        //hide the div 
        hideSlide();
        slides();
        ReverseSlideShow();
    }
    
    const hideSlide = () => {

        if(currentslide===0){
            hidenslide=2;
        }
        else if(currentslide===1){
            hidenslide=3;
        }
        else if(currentslide===2){
            hidenslide=0;
        }
        else if(currentslide===3){
            hidenslide=1;
        }
    }

    return (
        <div className='Slider-container'>
            
            <div className="navigation-section">
                <button onClick={playLast}><img src={arrowLeft} alt=""/> </button>
                <button onClick={playNext}><img src={arrowRight} alt=""/></button>
            </div>
            <img src={require('../../img/home-assets/7.png')} className="ellipse"  alt=""/>
            <div className="slider-inner"  ref={el => (sliderData = el)}>
                <div className="slides currentslide">
                    <div className="slide-img">
                        <img src={require('.././../img/home-assets/team-1.png')} className="object" data-value="-1"  alt=""/>
                    </div>
                    <div className="slide-content">
                        <h2>John Walker</h2>
                        <p>Business Developer</p>
                    </div>
                </div>
                <div className="slides nextslide">
                    <div className="slide-img">
                        <img src={require('.././../img/home-assets/team-2.png')}  className="object" data-value="1"  alt=""/>
                    </div>
                    <div className="slide-content">
                        <h2>Adam Warlock</h2>
                        <p>Business Developer</p>
                    </div>
                </div>
                <div className="slides">
                    <div className="slide-img">
                        <img src={require('.././../img/home-assets/team-3.jpg')}  className="object" data-value="-1"  alt=""/>
                    </div>
                    <div className="slide-content">
                        <h2>Star Lord</h2>
                        <p>Business Developer</p>
                    </div>
                </div>
                <div className="slides prevslide">
                    <div className="slide-img">
                        <img src={require('.././../img/home-assets/team-4.png')}  className="object" data-value="1"  alt=""/>
                    </div>
                    <div className="slide-content">
                        <h2>Madam Maya </h2>
                        <p>Business Developer</p>
                    </div>
                </div>
            </div>

           
        </div>
     );
}
 
export default Slider;