import './Headersection.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useRef } from 'react';
import { gsap, Power4 } from 'gsap';
import {Link} from 'react-router-dom';

const Headersection = () => {

    let menu= useRef(null);
    let menuLink= useRef(null);

    const timeline = gsap.timeline();
    const timeline2 = gsap.timeline();

    const openMenu = () =>{

        timeline.from(menu,{
            x:0,
            y:'-100%',
            ease:Power4.easeOut
        })
        .to(menu,{
            x:'-100%',
            y:'-100%',
            opacity:1,
            duration:0.8,
            ease:Power4.easeOut
        })
        .from('.menuDecor',{
            x:'100%',
            ease:Power4.easeOut
        }) 
        .to('.menuDecor',{
            x:'45%',
            ease:Power4.easeOut
        })
        timeline2
        .from(menuLink.children,{
            x:'-100%', 
            stagger:.2,
            ease:Power4.easeOut
        })
        .to(menuLink.children,{
            x:'0',
            stagger:.2,
            ease:Power4.easeOut
        })
          
    }

    const closeMenu = () =>{

        timeline
        .from('.menuDecor',{
            x:'45%',
            duration:0.2,
            ease:Power4.easeOut
        }) 
        .to('.menuDecor',{
            x:'100%',
            duration:0.2,
            ease:Power4.easeOut
        })
        .to(menu,{
            x:'0%',
            duration:0.5,
            ease:Power4.easeIn
        })

    }

    return ( 
        <header>  
            <div className="header-section header-wrapper">
                <div className='container-fluid'>
                    <div className="row">
                        <div className="col-3" >
                            <div className="mylogo">
                                <Link to="/"><img src={require('../../img/logo.png')} alt=""/></Link>
                            </div>
                        </div>
                        <div className="col-6"></div>

                        <div className="col-3">
                            <div className="iconmenu hoverred"  onClick={openMenu}>Menu</div>  
                        </div>
                    </div>
                </div>
                <div className="fullmenu"  ref={el => (menu = el)}>
                    <ul ref={el => (menuLink = el)}>
                        <li><span>Artesi8.com </span> <br/> <Link to="/"  onClick={closeMenu}>Home</Link></li>
                        <li><span>Know More </span> <br/> <Link to= "/about"  onClick={closeMenu}>About</Link></li>
                        <li><span>Other Business</span> <br/><Link to= "/partner"  onClick={closeMenu}>Partners</Link> </li>
                        <li><span>Connect Today</span> <br/><a href="tel:206-696-2778"  onClick={closeMenu}>Contact</a></li> 
                    </ul>
                    <div className="closebtn hoverred" onClick={closeMenu}>
                        Close
                    </div>
                    <div className="menuDecor">
                        <h1>Artesi8</h1>
                    </div>
                </div>
            </div>
            <div className="social-icon">
                <ul>
                    <li>
                        <img src={require('../../img/icon/facebook.png')} alt=""/>
                    </li>
                    <li>
                        <img src={require('../../img/icon/ig.png')} alt=""/>
                    </li>
                    <li>
                        <img src={require('../../img/icon/twitter.png')} alt=""/>
                    </li>
                </ul>
            </div>
        </header>
     );
}
 
export default Headersection;

