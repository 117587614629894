import "./HeroSection.css";
import { motion } from "framer-motion";
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import cementMixerImg from '../../img/home-assets/bannerimg1.svg'
import MaskImg from '../../img/home-assets/bannerimg2.svg'
import PaintImg from '../../img/home-assets/bannerimg3.svg'

const HeroSection = () => {

    const [mousePosition, setMousePosition]=useState({
        x:0,
        y:0
    });
    
    const [cursorVariants, setcursorVariants]=useState("default");
    
    useEffect(()=>{
    const mouseMove = e => {
        setMousePosition({
        x:e.clientX,
        y:e.clientY
        })
    }
    const textEnter = () => {
        setcursorVariants('textHover');
    }
    const textLeave =() =>{
        setcursorVariants('default');
    }
    const BlackBg = () =>{
        setcursorVariants('cursorWhite');
    }

    window.addEventListener("mousemove",mouseMove);
    //change the cursor size on hovering heading in the hero section

    const heroSec = document.querySelector('.mousehighliter');
    const header = heroSec.querySelectorAll('h2');
    for(let i=0; i<header.length; i++){
    header[i].addEventListener("mouseenter",textEnter);
    header[i].addEventListener("mouseleave",textLeave);
    }
    
    
    //if the background is black turn the cursor color to yellow
    const blackbg = document.querySelectorAll('.bgdark');
    for(let x = 0; x < blackbg.length; x++){
        blackbg[x].addEventListener("mousemove",BlackBg);
        blackbg[x].addEventListener("mouseleave",textLeave);
    }

    //for Hamburger Menu 
    const mouseOnMenu = document.querySelector('.fullmenu');
    mouseOnMenu.addEventListener("mousemove",BlackBg);
        
    return()=>{window.removeEventListener("mousemove",mouseMove);}
    },[]);

    const variants ={
        default:{
            x:mousePosition.x - 16,
            y:mousePosition.y - 16
        },
        textHover: {
            height:150,
            width:150,
            x:mousePosition.x - 75,
            y:mousePosition.y - 75,
            backgroundColor:"#ffffff",
            mixBlendMode:"difference",
            
        },
        cursorWhite:{
            display:'none',
            x:mousePosition.x - 16,
            y:mousePosition.y - 16,
            backgroundColor:"#ffbe00",
            zIndex:9
        }
    }


    return ( 
        <>  
        
        <section className="hero-section mousehighliter">
            <motion.div className="cursor" variants={variants} animate={cursorVariants}/>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="row title-section title-top title-font" >
                            <div className="col-lg-4 col-sm-12">
                                <h2 data-aos="slide-right">Our</h2>
                            </div>
                            <div className="col-lg-4"></div>
                            <div className="col-lg-4 col-sm-12">
                                <div className="floating-img-right">
                                    <img data-aos="flip-right" data-aos-delay="200"   src={cementMixerImg} alt="concrete mixer" className="object" data-value="2"/>
                                </div>
                            </div>

                        </div>
                        <div className="row title-section title-mid title-font">

                            <div className="col-lg-3 col-sm-12">
                                <div className="floating-img-left">
                                    <img data-aos="flip-left" data-aos-delay="200"  src={MaskImg} alt="mask" className="object" data-value="-1"/>
                                </div>
                            </div>
                            <div className="col-lg-9 col-sm-12">
                                <h2 data-aos="slide-left">Working</h2>
                            </div> 
                            
                        </div>
                        <div className="row title-section title-bottom title-font">

                            <div className="col-lg-12 col-sm-12 ">
                                <h2 data-aos="slide-left">History</h2>
                                <p data-aos="slide-right" id="para">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ametnon,
                                    ut iaculis justo, maecenas. Amet,eu ac, semper diam nunc tortor et
                                    ultricies non. 
                                </p>
                            </div> 
                            <div className="floating-img-middle">
                                <img data-aos="flip-right" data-aos-delay="200" src={PaintImg} alt="paint brush" className="object" data-value="1"/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

        </>
     );
}


 
export default HeroSection;