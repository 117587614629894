import './DescriptionSection.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const DescriptionSection = () => {
    return ( 

        <section className='description-section'>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-lg-6">
                        <h2>What makes us <span>different</span></h2>
                        <p>
                        It could be said that we are different culturally or that we are co-located in two different 
                        cities? But that’s something that we have become accustomed to and it doesn’t really affect our 
                        day-to-day. Perhaps though, it adds travel interest and curiosity excitement?
                        </p>
                        <p>
                        The time difference is such that our projects don’t sleep since we conveniently pass them back 
                        and forth every 12 hours?
                        </p>
                        <button className='btn-sty-2'>Customer Experience</button>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                        <div className="desc-img">
                            <img data-aos="slide-up" data-aos-delay="100" src={require('.././../img/home-assets/desc-back.png')} alt="iron cutter "/>
                            <img data-aos="slide-up" src={require('.././../img/home-assets/desc.png')} alt="iron cutter "/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

     );
}
 
export default DescriptionSection;