
const Partner = () => {
    return ( 
        <>
            <div className="partner-wrapper pd100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="title">
                                <h2 data-aos="slide-left">Our<span> Partner</span></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-1"></div>
                        <div className="col-lg-2 col-6">
                            <div className="img-middle object" data-value="1" >
                                <img data-aos="flip-right" data-aos-delay="800" src={require('../img/partner/grid-img1.png')}/>
                            </div>
                        </div>
                        <div className="col-lg-2 col-6">
                            <div className="img-middle">
                                <img  data-aos="flip-left" data-aos-delay="1000" src={require('../img/partner/grid-img2.png')}/>
                                <img  data-aos="flip-left" data-aos-delay="1000" src={require('../img/partner/grid-img3.png')}/>
                            </div>
                        </div>
                        <div className="col-lg-2 col-6">
                            <div className="img-middle object" data-value="2" >
                                <img data-aos="flip-left" data-aos-delay="1200" src={require('../img/partner/grid-img4.png')}/>
                            </div>
                        </div>
                        <div className="col-lg-2 col-6">
                            <div className="img-middle">
                                <img data-aos="flip-right" data-aos-delay="1400" src={require('../img/partner/grid-img6.png')}/>
                                <img data-aos="flip-left" data-aos-delay="1400" src={require('../img/partner/grid-img5.png')}/>
                            </div>
                        </div>
                        <div className="col-lg-2 col-6">
                            <div className="img-middle object" data-value="1"  >
                                <img  data-aos="flip-right" data-aos-delay="1600" src={require('../img/partner/grid-img7.png')}/>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                    </div>
                </div>
            </div>
            <div className="pd80 bg-light">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-12">
                            <div className="heading-title textcenter">
                                <h2 data-aos="slide-left">Platform</h2>
                                <img data-aos="slide-up" src={require('../img/home-assets/4.png')} width="100%"/>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-3">
                            <img src={require('../img/logo/logo1.png')}/>
                        </div>
                        <div className="col-lg-9">
                            <p className='bigfont'>Lists small quantities of high end primary products and quality auxiliary products with the goal of fostering recurrent shopping experiences. We maintain a small pool of well.</p>
                            <p className='bigfont'>Ultimately this creates a quick, easy, user experience for In Trade and Out of Trade users.</p>
                        </div>
                        <div className="col-lg-8">
                            <img data-aos="flip-left" src={require('../img/partner/item2.png')}/>
                        </div>
                        <div className="col-lg-4">
                            <img data-aos="flip-right" src={require('../img/partner/item1.png')}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pd80 ">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-8">
                            <p className='bigfont'>A ubiquitous tool for many tasks and included on most tool lists for DIY projects. There aren't many workplace contexts for which it doesn't belong. What is drawn to our attention is that this tool crosses over to several specialised tasks and is represented in their Tool Ranges: As a Scraper Tool for multiple surfaces (Glass, Paint and Floors). As a Drywall Knife (Applicator, Joint Knife and Skim Coat).</p>
                            <p className='bigfont'>As well as shining a light on these different uses our directory offers a breakdown of key attributes for use in deciding on the right products.</p>
                        </div>
                        <div className="col-lg-4">
                            <img src={require('../img/logo/logo3.png')}/>
                        </div>
                    </div>
                    <div className="row puttyknifeimg">
                        <div className="col-lg-4">
                            <img data-aos="flip-left" src={require('../img/partner/item3.png')}/>
                            <img data-aos="flip-left" src={require('../img/partner/item4.png')}/>
                        </div>
                        <div className="col-lg-8">
                            <img data-aos="flip-right" src={require('../img/partner/item5.png')}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-black  pd80">
                <div className="container">
                    <div className="row align-items-center font-wht">
                        <div className="col-lg-4">
                            <img src={require('../img/logo/logowhite2.png')}/>
                        </div>
                        <div className="col-lg-8 ">
                            <p className='bigfont'>During the Covid19 Pandemic, traffic for this site has been sent to https://getusppe.org.</p>
                            <p className='bigfont'>Covid will shape contexts in key areas of development moving forward. Since inception, however, this site is a way to shine a light on the importance of NIOSH rated products in the workplace and in Hazardous Environments. In terms of navigating the small print labeling on NIOSH products, the site serves as an easy, quick reference and trusted directory.</p>
                        </div>
                        <div className="col-lg-12">
                            <p className='bigfont'>Beyond this, from a User Interface perspective, we outline a hierarchical approach to upper and lower levels of protection ranging from a simple Sports Dust Masks to N95s, P100s and all the way up to Supplied Air Units.</p>
                            <p className='bigfont'>Supplemental consideration for dust control measures are borrowed from the Abatement, Construction, Mining and Oilfield industries.</p>
                        </div>
                        <div className="col-lg-4">
                            <img data-aos="fade-down-right" src={require('../img/partner/item6.png')}  />
                        </div>
                        <div className="col-lg-4">
                            <img data-aos="fade-down" src={require('../img/partner/item7.png')} />
                        </div>
                        <div className="col-lg-4">
                            <img data-aos="fade-down-left" src={require('../img/partner/item8.png')} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="pd100 bg-light">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <p className='bigfont'>
                                Our Flagship Micro-Platform. If ever there was a term that cried out for disambugation - 
                                This is it! The journey to demystify this one has taken place over several years since 2002 
                                when we sold our first products online. Not only is this class of Knives named regionally 
                                throughout the world, the concept itself encompasses a wide range of products for which some 
                                are labeled specifically and many labeled more broadly.
                            </p>
                        </div>
                        <div className="col-lg-6">
                            <img src={require('../img/logo/logo4.png')}/>
                        </div>
                        <div className="col-lg-12">
                            <p className='bigfont'>The breakdown we try to assign is based on the trapezoidal, replacement blade system. However, we include takeoffs from this concept, including the Snap-Off Blade and other custom inventions. We also can't ignore contextual uses of the "Utility Knife" such as in the Kitchen.</p>
                            <p className='bigfont'>If there is a method to our madness in this project, it is centered on safety and all follow on aspects after a sale is made.</p>
                        </div>
                        <div className="col-lg-12">
                            <img src={require('../img/partner/item9.png')}/>
                        </div>
                    </div>
                </div>
            </div>
        </> 
    );
}
 
export default Partner;