import React, {useState} from 'react';
import './Slide.css';
import dataSlider from './dataSlider.js'; 
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import leftArrow from ".././../img/home-assets/arrow-left.svg"; 


const Slider = () => {
    
    const [slideIndex, setSlideIndex] = useState(1);

    const nextSlide = () => {
        if(slideIndex !== dataSlider.length){
            setSlideIndex(slideIndex + 1)
        } 
        else if (slideIndex === dataSlider.length){
            setSlideIndex(1)
        }
    } 
 
    return (
        <div className="container-slider">
            {dataSlider.map((obj, index) => {
                return (
                    <>
                    <div
                    key={obj.id}
                    className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
                    >
                        <div className="Teamslide">
                            <div className="row">
                                <div className="col-lg-6">
                                    <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet varius arcu egestas quis in amet nunc. Tristique ut auctor nulla dictum mattis egestas turpis. Elit pharetra massa, turpis urna curabitur aliquam nibh lacus. Platea pellentesque et viverra augue sit egestas. Vitae nisi in risus enim tempor, platea condimentum purus. Mi netus lacus condimentum tellus.
                                    </p>
                                    <div className="nextbtn">
                                        <button onClick={nextSlide} className="btn-slide next">
                                            <img src={leftArrow}  alt=""/>
                                        </button>
                                    </div>
                                    <div className="profile-desc">
                                        <h3>{obj.title}</h3>
                                        <p>{obj.subTitle} </p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <img src={obj.img} alt=""/>
                                </div>
                            </div>
                        </div>  
                    </div>
                    </>
                )
            })}
        </div>
    ) ;
}
 
export default Slider;
